import React, { Component } from 'react';
import STimer from './components/stimer/STimer';
import Records from './components/records/Records';
import './structure.css';
import './App.css';

class App extends Component {

  constructor(props){
    super(props);
    this.addNewRecord = this.addNewRecord.bind(this);
  }

  componentWillMount(){
    this.setState({
      records: []
    });
  }

  addNewRecord(record){
    const records = [record, ...this.state.records]
    this.setState({
      records: records
    });
  }

  render() {
    return (
      <div className="tweening-app">
        <header className="app-header">
          <div class="page-title">
          <h1>Tweening Pro</h1>
          </div>
          <div class="session">
            <button>Login</button>
          </div>
        </header>
        <div className="main-wrapper">
          <aside className="menu">
            <span>Menu & Tools</span>
          </aside>
          <div className="main">
            <STimer onStop={this.addNewRecord}/>
          </div>
          <aside className="results">
            <Records records={this.state.records}/>
          </aside>
        </div>
        <footer className="app-footer">By <a href="https://twitter.com/jcteo" target="_blank" rel="noreferer">@jcteo</a></footer>
      </div>
    );
  }
}

export default App;
