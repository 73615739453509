import React, { Component } from 'react';
import stimer from '@jcteo/stimer';
import './STimer.css';

class STimer extends Component {
  constructor(props){
    super(props);
    this.st = new stimer.stimer();
    this.STStatus = "off";
    this.interval = null;
    this.updateTime = 25; //each time that label time will be updated (miliseconds)
    this.sTimerStateController = this.sTimerStateController.bind(this);
    this.handleKeyEvents = this.handleKeyEvents.bind(this);
    this.handleTouchEvents = this.handleTouchEvents.bind(this);
    this.updateTimeLabel = this.updateTimeLabel.bind(this);
    this.run = this.run.bind(this);
    this.stop = this.stop.bind(this);
  }

  handleKeyEvents(event) {
    if(event.type !== "keyup") {
      return;
    }
    if(event.keyCode === 32){
      this.sTimerStateController()
    }
  }

  handleTouchEvents (event) {
    if(event.type === "touchend"){
      this.sTimerStateController()
    }
  }

  sTimerStateController(event){
    switch(this.STStatus){
      case("off"):
      case("stoped"):
        this.run();
        this.STStatus = "runing"
      break;
      case("runing"):
        this.stop();
        this.STStatus = "stoped"
      break;
      default:
      break;
    }
  }

  run(){
    this.st.start();
    this.interval = setInterval(this.updateTimeLabel, this.updateTime);
  }

  stop(){
    clearInterval(this.interval);
    var time = this.updateTimeLabel();
    this.st.stop();
    this.props.onStop(time)
  }

  updateTimeLabel(){
    var time = this.st.get()
    this.setState({timeLabel: this.st.format(time)});
    return time;
  }

  componentWillMount(){
    document.addEventListener("keyup", this.handleKeyEvents, false)
    this.setState({
      timeLabel: "00:00",
      fireSTimerStateController: false
    });
  }

  componentWillUnmountount(){
    document.removeEventListener("keyup", this.handleKeyEvents, false)
  }

  render() {
    return (
      <div class="STimer" onTouchEnd={this.handleTouchEvents}>
        <label class="STimer__label">{this.state.timeLabel}</label>
      </div>
    );
  }
}

export default STimer;
