import React, { Component } from 'react';
import stimer from '@jcteo/stimer';
import "./Records.css";

class Records extends Component {
  constructor(props){
    super(props);
    this.st = new stimer.stimer();
    this.getAvg = this.getAvg.bind(this);
  }

  getAvg( nitems ){
    if (isNaN(nitems) || this.props.records.length === 0 ){
      return "--:--";
    }
    if(this.props.records.length < nitems ){
      return "--:--";
    }
    if(this.props.records.length === 1 || nitems === 1){
      return this.st.format(this.props.records[0]);
    }

    if(nitems === 0){
      nitems = this.props.records.length;
    }

    let avg = this.props.records
      .slice(0, nitems)
      .reduce((a,b) => parseInt(a) + parseInt(b), 0 ) / nitems;

    return this.st.format(avg);
  }

  render (){
    return (
      <section className="records">
        <div className="computed-results full-block">
          <div className="avg">
            <label className="time-label">AVG: </label><label className="time-value">{this.getAvg(0)}</label>
          </div>
          <div className="avg">
            <label className="time-label">AVG 5: </label><label className="time-value">{this.getAvg(5)}</label>
          </div>
          <div className="avg">
            <label className="time-label">AVG 10: </label><label className="time-value">{this.getAvg(10)}</label>
          </div>
        </div>
        <div className="records-list">
          <ul>
            {this.props.records.map((time, i) => (
              <li>
                <label className="time-label">{this.props.records.length - i}:</label>
                <label className="time-value">{this.st.format(time)}</label>
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

export default Records
